<template>
  <div class="mobiDeal">
    <div v-title data-title="Mobi"></div>
    <div class="Navbar">
      <img @click="gotoback()" :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">
        <ul style=" display: flex;">
          <li :class="activeIndex == 1 ? 'active' : ''" @click="changIndex(1)">
            Mobi购买USDT
          </li>
          <li :class="activeIndex == 2 ? 'active' : ''" @click="changIndex(2)">
            存款到贝宝
          </li>
          <li :class="activeIndex == 3 ? 'active' : ''" @click="changIndex(3)">
            取款/出售USDT
          </li>
        </ul>
      </div>
    </div>
    <div>

      <div class="one-box" v-if="activeIndex == 1">
        <div class="title2" style="margin-top:43px">
          <div class="title2-item"></div>
          <div class="title-cont">第一步 下载安装</div>
          <div class="title2-item"></div>
        </div>
        <p style="color:var(--li-colortext)">扫描二维码进行下载，支持苹果，安卓系统。</p>
        <img :src="imgUrl + 'mobiDeal.jpg'" />

        <div class="title2">
          <div class="title2-item"></div>
          <div class="title-cont">第二步 注册购买</div>
          <div class="title2-item"></div>
        </div>

        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe1">
            <van-swipe-item v-for="(item, index) in imagesUrl" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>

      <div class="one-box" v-if="activeIndex == 2">
        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe2">
            <van-swipe-item v-for="(item, index) in imagesUrl2" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg2">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg2">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>

      <div class="one-box" v-if="activeIndex == 3">
        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe3">
            <van-swipe-item v-for="(item, index) in imagesUrl3" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg3">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg3">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import "vant/lib/swipe/style";

export default {
  data () {
    return {
      imgIndex: 0,
      activeIndex: 1,
      imagesUrl: [
        {
          img: this.imgUrl + 'mobi1.jpg',
          title: "1.登录页面选择大陆手机号码/非大陆手机号码→收到验证码",
        },
        {
          img: this.imgUrl + 'mobi2.jpg',
          title: "2.设置密码完成注册",
        },
        {
          img: this.imgUrl + 'mobi3.jpg',
          title: "3.选择【Mobi外场交易】",
        },
        {
          img: this.imgUrl + 'mobi4.jpg',
          title: "4.选择数字资产【USDT】>填写购买信息",
        },
        {
          img: this.imgUrl + 'mobi5.jpg',
          title: "5.购买信息填写完毕发布后可点击右上角记事本图标【订单与广告】查看。",
        }
      ],
      imagesUrl2: [
        {
          img: this.imgUrl + 'mobiDpt1.jpg',
          title: '1.点击【发送】'
        },
        {
          img: this.imgUrl + 'mobiDpt2.jpg',
          title: '2.选择数字货币【usdt】'
        },
        {
          img: this.imgUrl + 'mobiDpt3.jpg',
          title: '3.可通过【扫描二维码】或者【地址】的方式转账数字货币'
        },
        {
          img: this.imgUrl + 'mobiDpt4.jpg',
          title: '4.点击【接收】'
        },
        {
          img: this.imgUrl + 'mobiDpt5.jpg',
          title: '5.点击【显示地址】后长按地址字符复制后绑定到贝宝游戏账号'
        },
      ],
      imagesUrl3: [
        {
          img: this.imgUrl + 'mobiDraw1.jpg',
          title: '1.点击【USDT】'
        },
        {
          img: this.imgUrl + 'mobiDraw2.jpg',
          title: '2.点击【接收】'
        },
        {
          img: this.imgUrl + 'mobiDraw3.jpg',
          title: '3.【显示地址】后长按字符地址2秒后即完成复制，然后将其绑定至贝宝账号。待您的数字货币钱包与贝宝游戏账号绑定成功后即可使用其进行取款或存款'
        },
        {
          img: this.imgUrl + 'mobiDraw4.jpg',
          title: '4.点击【OMNI】并点击【复制地址】后可绑定到贝宝账号。待您的数字货币钱包与贝宝游戏账号绑定成功后即可使用其进行取款。'
        },
        {
          img: this.imgUrl + 'mobiDraw5.jpg',
          title: '5.点击【发现】>【Mobi场外交易】'
        },
        {
          img: this.imgUrl + 'mobiDraw6.jpg',
          title: '6.选择币种USDT后点击下方【十】'
        },
        {
          img: this.imgUrl + 'mobiDraw7.jpg',
          title: '7.点击【出售】'
        },



      ],
    };
  },
  mounted () { },
  methods: {
    pervImg () {
      this.$refs.swipe1.prev();
    },
    nextImg () {
      this.$refs.swipe1.next();
    },

    pervImg2 () {
      this.$refs.swipe2.prev();
    },
    nextImg2 () {
      this.$refs.swipe2.next();
    },

    pervImg3 () {
      this.$refs.swipe3.prev();
    },
    nextImg3 () {
      this.$refs.swipe3.next();
    },
    changimgIndex (index) {
      if (index == this.imgIndex) return;
      this.imgIndex = index;
    },
    gotoback () {
      this.$router.go(-1)
      // uni.navigateBack({});
    },
    changIndex (index) {
      if (index == this.activeIndex) return;
      this.imgIndex = 0;
      this.activeIndex = index;
    },
  },
};
</script>
<style></style>
<style lang="less">
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.my-swipe {
  margin-top: 24px;
  padding-bottom: 50px;
}

.van-swipe__indicator {
  background-color: #999;
  width: 10px;
  height: 10px;
}

body {
  background-color: #f5f5f5;
}

ul,
li {
  list-style: none;
}

.mobiDeal {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;

    >img {
      height: 20px;
      width: 20px;
    }

    .title {
      font-size: 13px;
      flex: 1;
      text-align: center;
      color: #000;

      ul {
        height: 43px;
      }

      li {
        height: 43px;
        line-height: 43px;
        margin: 0 10px;
      }

      .active {
        color: #5780e9;
        border-bottom: 2px solid #5780e9;
      }
    }
  }

  .one-box {
    margin: 0 20px;
    margin-top: 64px;

    .lunbo-box {
      position: relative;

      .zuo {
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        width: 25px;
        height: 25px;

        >img {
          width: 100%;
          height: 100%;
        }
      }

      .you {
        position: absolute;
        top: calc(50% - 30px);
        right: 0;
        width: 22px;
        height: 22px;

        >img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        margin: 20px 40px;
      }
    }

    .title2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #6386fe;

      .title2-item {
        width: 12px;
        height: 12px;
        border: 5px solid royalblue;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 10px;
      }
    }

    >p {
      text-align: center;
      font-size: 14px;
      color: #000;
      margin: 10px 20px;
      font-weight: 400;
    }

    >img {
      padding: 15px 60px 30px 60px;
      width: 100%;
    }
  }
}
</style>
